import React from 'react'
import styles from './Copyright.module.scss'

const Copyright = () => (
  <div className={styles['copyright']}>
    © {new Date().getFullYear()} All rights reserved
  </div>
)

export default Copyright
